var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useMemo, useRef, useEffect } from "react";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import ThreePRoundedIcon from "@mui/icons-material/ThreePRounded";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import Chip from "@mui/joy/Chip";
import CircularProgress from "@mui/joy/CircularProgress";
import IconButton from "@mui/joy/IconButton";
import Input from "@mui/joy/Input";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/material/Stack";
import { Transition } from "react-transition-group";
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
var API_URL = "https://factory.wearegenial.com";
var defaultChatBubbleConfig = {
    theme: "light",
    primaryColor: "#000000",
    position: "right",
};
// Define the shake animation keyframes
var shake = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  0%, 100% {\n    transform: translateX(0);\n  }\n  10%, 30%, 50%, 70%, 90% {\n    transform: translateX(-2px);\n  }\n  20%, 40%, 60%, 80% {\n    transform: translateX(2px);\n  }\n"], ["\n  0%, 100% {\n    transform: translateX(0);\n  }\n  10%, 30%, 50%, 70%, 90% {\n    transform: translateX(-2px);\n  }\n  20%, 40%, 60%, 80% {\n    transform: translateX(2px);\n  }\n"])));
var ShakingIcon = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: inline-block;\n  &.shake {\n    animation: ", " 1s;\n  }\n"], ["\n  display: inline-block;\n  &.shake {\n    animation: ", " 1s;\n  }\n"])), shake);
function App(props) {
    var _this = this;
    var initMessageRef = useRef(null);
    var chatBoxRef = useRef(null);
    var iconRef = useRef(null);
    var _a = useState({
        isOpen: false,
        showInitialMessage: false,
        showHelp: true,
        showCaptureForm: false,
        isCaptureLoading: false,
        visitorEmail: "",
        config: defaultChatBubbleConfig,
    }), state = _a[0], setState = _a[1];
    useEffect(function () {
        handleFetchAgent();
        var interval = setInterval(function () {
            if (iconRef.current) {
                iconRef.current.classList.add("shake");
                setTimeout(function () {
                    if (iconRef.current) {
                        iconRef.current.classList.remove("shake");
                    }
                }, 500);
            }
        }, 4000);
        return function () { return clearInterval(interval); };
    }, [props.agentId]);
    var handleFetchAgent = function () { return __awaiter(_this, void 0, void 0, function () {
        var res, data, agentConfig_1, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, fetch("".concat(API_URL, "/api/external/agents/").concat(props.agentId))];
                case 1:
                    res = _a.sent();
                    return [4 /*yield*/, res.json()];
                case 2:
                    data = (_a.sent());
                    agentConfig_1 = data === null || data === void 0 ? void 0 : data.interfaceConfig;
                    setState(function (prevState) { return (__assign(__assign({}, prevState), { config: __assign(__assign({}, prevState.config), { primaryColor: (agentConfig_1 === null || agentConfig_1 === void 0 ? void 0 : agentConfig_1.primaryColor) || prevState.config.primaryColor, position: (agentConfig_1 === null || agentConfig_1 === void 0 ? void 0 : agentConfig_1.position) || prevState.config.position }) })); });
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    console.error("Failed to fetch agent config:", error_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var textColor = useMemo(function () {
        return state.config.primaryColor === "#000000" ? "#ffffff" : "#000000";
    }, [state.config.primaryColor]);
    var transitionStyles = {
        entering: { opacity: 0 },
        entered: { opacity: 1 },
        exiting: { opacity: 0 },
        exited: { opacity: 0 },
    };
    var Capture = useMemo(function () {
        var Component = null;
        if (state.showHelp || state.visitorEmail) {
            Component = (React.createElement(Stack, { sx: { mb: -1 } },
                state.visitorEmail && (React.createElement(Chip, { size: "sm", color: "success", variant: "soft", sx: { mr: "auto" }, endDecorator: React.createElement(CheckRoundedIcon, null) }, "Help requested")),
                !state.visitorEmail && !state.showCaptureForm && (React.createElement(Button, { size: "sm", variant: "plain", color: "neutral", startDecorator: React.createElement(ThreePRoundedIcon, null), sx: { mr: "auto" }, onClick: function () { return setState(__assign(__assign({}, state), { showCaptureForm: true })); } }, "Help")),
                !state.visitorEmail && state.showCaptureForm && (React.createElement("form", { onSubmit: function (e) {
                        e.preventDefault();
                        e.stopPropagation();
                        var form = e.target;
                        var email = form.email.value;
                        if (email) {
                            setState(__assign(__assign({}, state), { isCaptureLoading: true }));
                            setTimeout(function () {
                                setState(__assign(__assign({}, state), { showHelp: false, isCaptureLoading: false, visitorEmail: email }));
                                localStorage.setItem("visitorEmail", email);
                            }, 2000);
                        }
                    } },
                    React.createElement(Stack, { direction: "row", gap: 0.5, sx: { width: "100%" } },
                        React.createElement(IconButton, { size: "sm", variant: "plain", onClick: function () {
                                setState(__assign(__assign({}, state), { showCaptureForm: false }));
                            } },
                            React.createElement(ArrowBackRoundedIcon, null)),
                        React.createElement(Input, { sx: { width: "100%" }, size: "sm", name: "email", type: "email", placeholder: "Leave your email to get contacted by the team", required: true, disabled: state.isCaptureLoading, endDecorator: React.createElement(IconButton, { color: "neutral", type: "submit", disabled: state.isCaptureLoading }, state.isCaptureLoading ? (React.createElement(CircularProgress, { size: "sm", variant: "soft" })) : (React.createElement(CheckRoundedIcon, null))) }))))));
        }
        return Component;
    }, [state]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Transition, { nodeRef: initMessageRef, in: state.showInitialMessage && !state.isOpen, timeout: 0, mountOnEnter: true, unmountOnExit: true }, function (s) { return (React.createElement(Stack, { ref: initMessageRef, sx: __assign(__assign(__assign({ position: "fixed", bottom: 100, transition: "opacity 300ms ease-in-out", opacity: 0, zIndex: 9999999998 }, transitionStyles[s]), (state.config.position === "left"
                ? {
                    left: "20px",
                }
                : {})), (state.config.position === "right"
                ? {
                    right: "20px",
                }
                : {})) },
            React.createElement(Card, { sx: {
                    width: "100%",
                    maxWidth: 1000,
                    display: "flex",
                    boxShadow: "md",
                } },
                React.createElement(Typography, null, "Hi, how can I help you?")))); }),
        React.createElement(Box, { sx: {
                overflow: "visible",
                position: "fixed",
                height: "60px",
                bottom: "20px",
                zIndex: 9999999999,
                right: state.config.position === "right" ? "20px" : "auto",
                left: state.config.position === "left" ? "20px" : "auto",
            } },
            React.createElement(Transition, { nodeRef: chatBoxRef, in: state.isOpen, timeout: 0, mountOnEnter: true, unmountOnExit: true }, function (s) { return (React.createElement(Card, { ref: chatBoxRef, variant: "outlined", sx: function (theme) {
                    var _a;
                    return (__assign(__assign(__assign(__assign({ zIndex: 9999, position: "absolute", bottom: "80px", display: "flex", flexDirection: "column", boxSizing: "border-box", boxShadow: "md", transition: "opacity 150ms ease-in-out", opacity: 0 }, transitionStyles[s]), { width: "500px", height: "80vh", minHeight: "450px", maxHeight: "800px" }), (state.config.position === "left"
                        ? { left: "20px", transform: "translateX(0)" }
                        : { right: "20px", transform: "translateX(0)" })), (_a = {}, _a[theme.breakpoints.down("sm")] = {
                        width: "100vw",
                        maxWidth: "100vw",
                        bottom: "-20px",
                        height: "80vh",
                        right: state.config.position === "left" ? "auto" : "-20px",
                        left: state.config.position === "left" ? "-20px" : "auto",
                        transform: "translateX(0px)",
                    }, _a)));
                } },
                React.createElement("iframe", { src: "https://factory.wearegenial.com/agents/".concat(props.agentId, "/iframe/?lang=").concat(props.lang), width: "100%", height: "100%", frameBorder: "0" }))); }),
            React.createElement(IconButton, { variant: "solid", onClick: function () {
                    return setState(__assign(__assign(__assign({}, state), { isOpen: !state.isOpen }), (!state.isOpen
                        ? {
                            showInitialMessage: false,
                        }
                        : {})));
                }, sx: {
                    backgroundColor: "".concat(state.config.primaryColor, "!important"),
                    width: "50px",
                    height: "50px",
                    zIndex: 9999,
                    borderRadius: "100%!important",
                    color: "".concat(textColor, "!important"),
                    transition: "all 100ms ease-in-out",
                    border: "none!important",
                    "&:hover": {
                        backgroundColor: "".concat(state.config.primaryColor, "!important"),
                        filter: "brightness(0.9)",
                        transform: "scale(1.05)",
                    },
                } }, state.isOpen ? (React.createElement(ClearRoundedIcon, null)) : (React.createElement(ShakingIcon, { ref: iconRef },
                React.createElement(AutoAwesomeIcon, null)))))));
}
export default App;
var templateObject_1, templateObject_2;
